/* Bootstrap  */
//import "bootstrap";
//import { Modal } from 'bootstrap'

/* Custom SCSS */
import "./index.scss";

/* SwiperJS */
import Swiper /*, { Navigation, Pagination, Scrollbar }*/ from 'swiper';
import 'swiper/css';
//import 'swiper/css/navigation';
//import 'swiper/css/pagination';
//import 'swiper/css/scrollbar';
//Swiper.use([Scrollbar]);
// init Swiper:
/*
const swiper = new Swiper('.swiper', {  direction: 'vertical',
    loop: true,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    },
    modules: [Navigation, Pagination, Scrollbar],
    ...
});
*/

/* Fancybox */
//import { Fancybox } from "@fancyapps/ui";
//import "@fancyapps/ui/dist/fancybox.css";

/* Gsap */
//import { gsap } from "gsap";
//import { ScrollTrigger } from "gsap/ScrollTrigger.js";
//gsap.registerPlugin(ScrollTrigger);

//var Isotope = require('isotope-layout');

jQuery(document).ready( function($) { 
    
    const swiperSlider = new Swiper('.slider_banner', {
        slidesPerView: 1,
        loop: true,
    });

    $('body').on('click', '.menu-item .material-symbols-outlined', function() {
        $(this).next('.dropdown-menu').toggleClass('d-none');
    });

    if($(window).width()>990) {

        $('body').on('mouseenter', '.dropdown', function(){ 
            $(this).find('.dropdown-menu').removeClass('d-none');
        });
        $('body').on('mouseleave', '.dropdown', function(){ 
            $(this).find('.dropdown-menu').addClass('d-none');
        });

    }


    $('.link-ev-modal').click(function() {
        $(this).next('.modal').appendTo("#wrapperModalLavora");
        var dest = $(this).data('dest');
        const myModal = new Modal(dest)
        myModal.show();
    });

    $('.navbar-toggler').click(function() {
        $(this).toggleClass('collapsed');
        $('#navbarToggler').toggleClass('aperto');
        $('body').toggleClass('noscroll');
    });

    if($(window).width()<990) {
        $('.titolo_sidebar_shop').click(function(e) {
            e.preventDefault();
            $('.sidebar_shop').toggleClass('open');
        })
    }
    
    
    /*
    $('.business_model_canvas_grid .row').each(function(index, el) {
        new Isotope( el, {
            itemSelector: '.wp-block-evidenzio-column',
            percentPosition: true,
            masonry: {
              // use outer width of grid-sizer for columnWidth
              columnWidth: '.wp-block-evidenzio-column'
            }
        })    
    });
    */

    /*
    gsap.utils.toArray(".ev_header").forEach((section) => {
        gsap
        .timeline({
          scrollTrigger: {
            trigger: section,
            start: 'top center',
            //markers: true,
          },
        })
        .from(
          $(section).find('.article_thumbnail'),
          { y: 80, 'opacity':0, duration: 1.5, stagger: .5 }
        );
    });
    */

    $('.sidebar_shop .titolo-widget-sidebar').click(function() {
        $(this).toggleClass('aperto');
        $(this).next('.woocommerce-widget-layered-nav-list').toggleClass('aperto');
    });
    $('.filtro_colore').parents('.woocommerce-widget-layered-nav-list').addClass('wrapper_filtro_colore');

    $('a.scroll-link, .scroll-link>a').click(function(e) {
        e.preventDefault();
        var link = $(this).attr('href');
        $('html, body').animate({ scrollTop: $('#'+link).offset().top-200}, 800);
    });

    

    //Fancybox.bind('.woocommerce-product-gallery__image a');




    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        var header_top = $('.header-top').outerHeight();

        if(scroll > header_top) {
            $('body').addClass('stickyHeader');
        } else {
            $('body').removeClass('stickyHeader');
        }

    });



    /*
    if($('.wpcf7-form').length) {
        $('input[type=text], input[type=email], textarea').each(function(e){
            var ph = $(this).attr('placeholder');
            if($(this).hasClass('wpcf7-validates-as-required')) {
                ph += ' *';
            }
            if( ph ){
                $(this).attr('placeholder', '');
                $(this).parent().append('<label>' + ph + '</label>');
                $(this).parent().append('<span class="focus-border"></span>');

            }
        });
        $('input[type=text], input[type=email], textarea').keyup(function(e){
            var content = $(this).val();
            if(content=='') {
                $(this).removeClass('has-content');
            } else {
                $(this).addClass('has-content');
            }
        });
  
        $('.wpcf7-select').each(function() {
          $(this).parent().prev('label').addClass('placeholder_select');
          $(this).on('change', function() {
              $(this).parent().prev('label').addClass('selected');
          });
        });
  

        $('.upload_wrapper').click(function() {
            $(this).next().find('input[type="file"]').click();
        });
        $('body').on('change', '.wpcf7-file', function(e) {
            var filename = $(this).val().split('\\').pop();
            $('<span>'+filename+'</span>').insertAfter($(this));
        });
        $('.wpcf7-select option:first').html('');

        $('.wpcf7-spinner').appendTo('.wpcf7-form');

    }*/

    if($(window).width()<990) {
        $('footer .titolo-widget-sidebar').click(function() {
            if($(this).find('.toggler').length) $(this).nextAll('div').slideToggle();
        });
        $('footer .toggler').each(function() {
            $(this).parent('.titolo-widget-sidebar').nextAll().hide();
        });
    }

	$('.variations select').each(function() {
		var opz = $(this).val();
		console.log($(this).val());
		$('a[data-opzione="'+opz+'"]').addClass('selected');
	});

    if($('.wc-block-product-categories').length) {
        $('.wc-block-product-categories > ul > li').each(function(){
            if($(this).find('ul').length) {
                $(this).append('<span class="toggler"></span>');
            }
        });
        $('body').on('click', '.wc-block-product-categories .toggler', function() {
            $(this).prev('ul').toggle();
            $(this).toggleClass('aperto');
        });
        var currentH1 = $('#content h1').text();
        $('.wc-block-product-categories a').each(function(){
            var titolo = $(this).text();
            if(titolo==currentH1) {
                $(this).addClass('active');
                $(this).parents('ul').prevAll('a').addClass('active');
                $(this).parents('ul').show();
                $(this).parents('ul').next('.toggler').addClass('aperto');
            }
        });
    }

    $( 'body' ).on( 'click', '.qtyupdate', function(e) {

        e.preventDefault();

        var item_hash = $( this ).data( 'hash' );
        var item_quantity = $( this ).data( 'qty' );

        if($(this).hasClass('disabled')) return false; 

        $('.loader-spinner').removeClass('hidden');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_auth_object.ajaxurl,
            data: {
                'action' : 'update_item_from_cart', 
                'cart_item_key' : item_hash, 
                'qty' : item_quantity  
            },
            success: function(data) {
                if ( data && data.fragments ) {
                    $.each( data.fragments, function( key, value ) {
                        $( key ).replaceWith( value );
                    });
                    $( document.body ).trigger( 'wc_fragments_refreshed' );
                }
            }
        });  

    });



    $( 'body' ).on( 'click', '.add_coupon_custom button', function(e) {
        e.preventDefault();
        var data = {
            coupon_code: jQuery(this).prev('input').val(), 
            security: ajax_auth_object.apply_coupon_nonce
        };

        $('.loader-spinner').removeClass('hidden');
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/?wc-ajax=apply_coupon',
            data: data,
            complete: function() {
                //$( document.body ).trigger( 'applied_coupon', [ coupon_code ] );
                get_minicart();
            }
        });  

    });



    function get_minicart() {
        var data = {
            'action' : 'get_minicart', 
            coupon_code: jQuery(this).prev('input').val(), 
            security: ajax_auth_object.apply_coupon_nonce
        };

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax_auth_object.ajaxurl,
            data: data,
            success: function(data) {
                if ( data && data.fragments ) {
                    $.each( data.fragments, function( key, value ) {
                        $( key ).replaceWith( value );
                    });
                    $( document.body ).trigger( 'wc_fragments_refreshed' );
                }
            }
        });  

    }

    $('.add_coupon_box .toggler').click(function() {
        $('.add_coupon_custom').toggleClass('hidden');
    }) 

    $('body').on('click', '.close_cart', function() {
        $('#minicart_box').addClass('d-none');
    });

    $('body').on('click', '.menu_cart', function() {
        var count = $(this).find('.cart-contents-count').data('count');
        if(count>0) $('#minicart_box').removeClass('d-none');
    });

    $('body').on( 'added_to_cart', function(){
        $('#minicart_box').removeClass('d-none');
    });


	if($('.varianti_radio').length) {
        $('.varianti_radio a').click(function(e) {
            e.preventDefault();
            var attributo = $(this).attr('data-attributo');
            var opzione = $(this).attr('data-opzione');
            $(this).parent().parent('.row').find('a').removeClass('selected');
            $(this).addClass('selected');
            $('select#'+attributo).val(opzione).change();
            $('.varianti_radio a').each(function(){
                var l = $('select#'+$(this).attr('data-attributo')).find('option[value="'+$(this).attr('data-opzione')+'"]');
                if( l.length==0 ) {
                    $(this).parent('div').addClass('disabled');
                } else {
                    $(this).parent('div').removeClass('disabled');
                }
            });
            $('.single_variation_wrap p.price').hide();
        })    
	}





    $('.titolo_pb').each(function() {
        var ancora = $(this).data('ancora');
        if( !(typeof ancora === 'undefined' || ancora === null) ){
            $(this).on('click', function() {
                if($('#idmanuale_'+ancora).length) 
                    $('html, body').animate({ scrollTop: $('#idmanuale_'+ancora).offset().top}, 800);
                else
                    console.log('#idmanuale_'+ancora+' non trovato!!');
            });
        }
    });

    if($(window).width()<990) {
        $('.mobile-toggler .titolo_pb').click(function() {
            $(this).toggleClass('aperto');
            $(this).parents('.mobile-toggler').toggleClass('aperto');
        });
    }


        // ricerca ajax
        (function ($) {
            $.fn.delayKeyup = function(callback, ms){
                var timer = 0;
                $(this).keyup(function(){                   
                    clearTimeout (timer);
                    timer = setTimeout(callback, ms);
                });
                return $(this);
            };
        })(jQuery);

        for (let ii = 1; ii < 4; ii++) {
            $('.search_form #search-'+ii).delayKeyup(function(){ 
        
                    var string = $('.search_form #search-'+ii).val();
                    if(string.length>3) {
                        var security = $('.search_form #security').val();
                        $.ajax({
                            type: 'POST',
                            dataType: 'json',
                            url: ajax_auth_object.ajaxurl,
                            data: {
                                'action': 'ajaxsearch',
                                'string': string,
                                'security': security,
                                'lang': ajax_auth_object.languagecode
                            },
                            beforeSend: function() {
                                $('.search_results').html('<div class="lds-ripple"><div></div><div></div></div>');
                            },
                            success: function (data) {
                                var html = '<div class="row">';
    
                                $.each(data, function(index, item) {

                                    html += '<article class="mb-2 mb-lg-3 col-12 col-lg-4 product type-product status-publish has-post-thumbnail">';
                                    html += '<div class="wrapper"><div class="top">'+item.thumb+'</div>';
                                    html += '<div class="bottom p-2"><h3 class="entry-title">'+item.post_title+'</h3><p>'+item.post_excerpt+'</p>';
                                    html += '<a href="'+item.url+'" data-quantity="1" class="button product_type_variable add_to_cart_button">Scegli</a></div></div></article>';
                                
                                });

                                html += '</div>';
                                
                                $('.search_results').html(html);
                            }
                        });
                    }
            }, 500);
        }

});

function elementPageParallax(elementClass, gap, section) {

    gsap
    .timeline({
      scrollTrigger: {
        trigger: section,
        scrub: true,
        start: 'top bottom',
        end: 'bottom top',
        //markers: true,
      },
    })
    .fromTo(
      elementClass,
      { y: gap },
      { y: -gap }
    );
  
}

function mostraImmagini(section) {

    gsap
    .timeline({
      scrollTrigger: {
        trigger: section,
        start: 'top bottom',
        //markers: true,
      },
    })
    .from(
      section,
      { y: 30, 'opacity':0, duration: 1 }
    );
  
}

jQuery( function( $ ) {

	// wc_add_to_cart_params is required to continue, ensure the object exists
	if ( typeof wc_add_to_cart_params === 'undefined' )
		return false;
	
	// Ajax add to cart
	$( document ).on( 'click', '.variations_form .single_add_to_cart_button', function(e) {
		
		e.preventDefault();
		
		var $variation_form = $( this ).closest( '.variations_form' );
		var var_id = $variation_form.find( 'input[name=variation_id]' ).val();
		var product_id = $variation_form.find( 'input[name=product_id]' ).val();
		var quantity = $variation_form.find( 'input[name=quantity]' ).val();
		
		//attributes = [];
		$( '.ajaxerrors' ).remove();
		var item = {},
			check = true;
			
			var variations = $variation_form.find( 'select[name^=attribute]' );
			
			/* Updated code to work with radio button - mantish - WC Variations Radio Buttons - 8manos */ 
			if ( !variations.length) {
				variations = $variation_form.find( '[name^=attribute]:checked' );
			}
			
			/* Backup Code for getting input variable */
			if ( !variations.length) {
    			variations = $variation_form.find( 'input[name^=attribute]' );
			}
		
		variations.each( function() {
		
			var $this = $( this ),
				attributeName = $this.attr( 'name' ),
				attributevalue = $this.val(),
				index,
				attributeTaxName;
		
			$this.removeClass( 'error' );
		
			if ( attributevalue.length === 0 ) {
				index = attributeName.lastIndexOf( '_' );
				attributeTaxName = attributeName.substring( index + 1 );
		
				$this
					.addClass( 'required error' )
					.before( '<div class="ajaxerrors"><p>Please select ' + attributeTaxName + '</p></div>' )
		
				check = false;
			} else {
				item[attributeName] = attributevalue;
			}
		
		} );
		
		if ( !check ) {
			return false;
		}
		
		var $thisbutton = $( this );

		if ( $thisbutton.is( '.variations_form .single_add_to_cart_button' ) ) {

			$thisbutton.removeClass( 'added' );
			$thisbutton.addClass( 'loading' );

			var data = {
				action: 'woocommerce_add_to_cart_variable_rc',
			};

			$variation_form.serializeArray().map(function (attr) {
				if (attr.name !== 'add-to-cart') {
				    if (attr.name.endsWith('[]')) {
				        let name = attr.name.substring(0, attr.name.length - 2);
				        if (!(name in data)) {
				            data[name] = [];
				        }
				        data[name].push(attr.value);
				    } else {
				        data[attr.name] = attr.value;
				    }
				}
			});

			// Trigger event
			$( 'body' ).trigger( 'adding_to_cart', [ $thisbutton, data ] );

			// Ajax action
			$.post( wc_add_to_cart_params.ajax_url, data, function( response ) {

				if ( ! response ) {
					return;
				}

				if ( response.error && response.product_url ) {
					window.location = response.product_url;
					return;
				}

				// Redirect to cart option
				if ( wc_add_to_cart_params.cart_redirect_after_add === 'yes' ) {
					window.location = wc_add_to_cart_params.cart_url;
					return;
				}

				// Trigger event so themes can refresh other areas.
				$( document.body ).trigger( 'added_to_cart', [ response.fragments, response.cart_hash, $thisbutton ] );

			});

			return false;

		} else {
			
			return true;
		}

	});

});

if($('.scrollToTopBtn').length) {
    var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    var rootElement = document.documentElement;
    function handleScroll() {
      var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
      if (rootElement.scrollTop / scrollTotal > 0.7) {
        scrollToTopBtn.classList.add("showBtn");
      } else {
        scrollToTopBtn.classList.remove("showBtn");
      }
    }
    function scrollToTop() {
      rootElement.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);
}
